<style scoped lang="less">
  .resume-worker {
    background: #FAFAFA;
    padding: 15px 30px;
    box-sizing: border-box;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img.avatar {
    background-color: #FFF;
    width: 119px;
    height: 128px;
    object-fit: contain;
  }
  .info {
    flex: 1;
    margin-left: 25px;
  }
  .name {
    font-size: 16px;
    color: #000;
    padding-top: 8px;
  }
  .cells {
    margin-top: 18px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
  .iconfont {
    margin-right: 10px;
  }
  .field {
    color: rgba(0, 0, 0, .7);
  }
  .value {
    color: #000;
  } 
  .bottom {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<template>
  <div class="resume-worker" @click="click">
    <div class="top">
      <div class="name" v-if="!onlyHead">{{data.name}}</div>
      <div class="item" v-if="!onlyHead" style="margin:5px 0 8px;">
            <i class="iconfont icon-yonghu1 theme-text-color"></i>
            <span class="field">职务：</span>
            <span class="value">{{positionText || '-'}}</span>
          </div>
      <img :src="src" class="avatar" />
    </div>
    <div class="bottom">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { getWorkerAvatar } from '../../resume/libs'

export default {
  props: {
    data: { type: Object, default: () => ({}) },
    onlyHead: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      src: null,
      loading: {
        file: false
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.addLazyLoad()
        this.lazyLoad()
      }
    }
  },
  computed: {
    // positionList () {
    //   return this.$store.getters.positionList
    // },
    // positionMap () {
    //   let data = {}
    //   this.positionList.forEach(v => data[v.key] = v.label)
    //   return data
    // },
    positionText () {
      let newData = []
      let userOrgRoleList = this.data && this.data.userOrgRoleList ? this.data.userOrgRoleList : []
      userOrgRoleList.forEach(v => {
        if (v.roleName && !newData.includes(v.roleName)) {
          newData.push(v.roleName)
        }
      })
      return newData.join(',')
    }
  },
  methods: {
    click () {
      this.$emit('clickWorker')
    },
    async loadFile () {
      this.loading.file = true
      this.src = this.data.headFileId ? await getWorkerAvatar(this.data.headFileId, 'small') : null
      this.loading.file = false
    },
    lazyLoad () {
      const wHeight = window.innerHeight
      const margin = 100
      const { top } = this.$el.getBoundingClientRect()

      if (top > -margin && top < (wHeight + margin)) {
        this.loadFile()
        this.rmeoveLazyLoad()
      }
    },
    addLazyLoad () {
      this.$el.offsetParent.addEventListener('scroll', this.lazyLoad)
    },
    rmeoveLazyLoad () {
      if (this.$el.offsetParent) {
        this.$el.offsetParent.removeEventListener('scroll', this.lazyLoad)
      }
    }
  },
  destroyed () {
    this.rmeoveLazyLoad()
  },
  mounted () {
    this.addLazyLoad()
    this.lazyLoad()
  }
}
</script>
